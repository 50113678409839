exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agroactiva-2022-farmer-js": () => import("./../../../src/pages/agroactiva2022/farmer.js" /* webpackChunkName: "component---src-pages-agroactiva-2022-farmer-js" */),
  "component---src-pages-agroactiva-2022-index-js": () => import("./../../../src/pages/agroactiva2022/index.js" /* webpackChunkName: "component---src-pages-agroactiva-2022-index-js" */),
  "component---src-pages-agroactiva-2022-investor-js": () => import("./../../../src/pages/agroactiva2022/investor.js" /* webpackChunkName: "component---src-pages-agroactiva-2022-investor-js" */),
  "component---src-pages-agroactiva-2022-merchant-js": () => import("./../../../src/pages/agroactiva2022/merchant.js" /* webpackChunkName: "component---src-pages-agroactiva-2022-merchant-js" */),
  "component---src-pages-agroactiva-2022-other-js": () => import("./../../../src/pages/agroactiva2022/other.js" /* webpackChunkName: "component---src-pages-agroactiva-2022-other-js" */),
  "component---src-pages-agroactiva-2022-success-js": () => import("./../../../src/pages/agroactiva2022/success.js" /* webpackChunkName: "component---src-pages-agroactiva-2022-success-js" */),
  "component---src-pages-contact-farmer-js": () => import("./../../../src/pages/contact/farmer.js" /* webpackChunkName: "component---src-pages-contact-farmer-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-investor-js": () => import("./../../../src/pages/contact/investor.js" /* webpackChunkName: "component---src-pages-contact-investor-js" */),
  "component---src-pages-contact-merchant-js": () => import("./../../../src/pages/contact/merchant.js" /* webpackChunkName: "component---src-pages-contact-merchant-js" */),
  "component---src-pages-contact-other-js": () => import("./../../../src/pages/contact/other.js" /* webpackChunkName: "component---src-pages-contact-other-js" */),
  "component---src-pages-get-in-touch-farmer-js": () => import("./../../../src/pages/get-in-touch/farmer.js" /* webpackChunkName: "component---src-pages-get-in-touch-farmer-js" */),
  "component---src-pages-get-in-touch-index-js": () => import("./../../../src/pages/get-in-touch/index.js" /* webpackChunkName: "component---src-pages-get-in-touch-index-js" */),
  "component---src-pages-get-in-touch-investor-js": () => import("./../../../src/pages/get-in-touch/investor.js" /* webpackChunkName: "component---src-pages-get-in-touch-investor-js" */),
  "component---src-pages-get-in-touch-merchant-js": () => import("./../../../src/pages/get-in-touch/merchant.js" /* webpackChunkName: "component---src-pages-get-in-touch-merchant-js" */),
  "component---src-pages-get-in-touch-other-js": () => import("./../../../src/pages/get-in-touch/other.js" /* webpackChunkName: "component---src-pages-get-in-touch-other-js" */),
  "component---src-pages-get-in-touch-success-js": () => import("./../../../src/pages/get-in-touch/success.js" /* webpackChunkName: "component---src-pages-get-in-touch-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-registro-productores-index-js": () => import("./../../../src/pages/registro-productores/index.js" /* webpackChunkName: "component---src-pages-registro-productores-index-js" */),
  "component---src-pages-registro-productores-success-js": () => import("./../../../src/pages/registro-productores/success.js" /* webpackChunkName: "component---src-pages-registro-productores-success-js" */)
}

